import {
  LogoutOutlined,
  VerticalAlignBottomOutlined,
  VerticalAlignTopOutlined,
} from "@ant-design/icons";
import { Button, Select, Radio, Flex } from "antd";
import { signOut } from "firebase/auth";
import { firebaseAuth } from "../../firebaseConfig";
import { useContext } from "react";
import { WishListContext } from "./WishListContext";
import { OrderByDirection } from "firebase/firestore";
import { WishListTags } from "./TagAdder";

const FilterTags = [
  {
    label: WishListTags.CHRISTMAS,
    value: WishListTags.CHRISTMAS,
  },
  {
    label: WishListTags.BIRTHDAY,
    value: WishListTags.BIRTHDAY,
  },
  {
    label: WishListTags.PRIMEDAY,
    value: WishListTags.PRIMEDAY,
  },
  {
    label: WishListTags.DONOTBUY,
    value: WishListTags.DONOTBUY,
  },
  {
    label: WishListTags.SELF,
    value: WishListTags.SELF,
  },
];

const ListMenuBar = () => {
  const { setSortType, setSortOrder, getItems, items, setItems } =
    useContext(WishListContext);

  const changeSortType = (value: string) => {
    setSortType(value);
    getItems(value);
  };

  const changeSortOrder = (e: any) => {
    setSortOrder(e.target.value as OrderByDirection);
    getItems(null, e.target.value as OrderByDirection);
  };

  const filterItems = (value: string) => {
    const filteredItems = items.filter((item) =>
      item.tags?.some((tag) => value === tag.name)
    );

    if (value === undefined) {
      getItems();
    } else {
      setItems(filteredItems);
    }
  };

  return (
    <Flex align="center" justify="space-between" wrap="wrap">
      <Flex justify="center">
        <Select
          defaultValue="date"
          style={{ width: 120, margin: "5px" }}
          onChange={changeSortType}
          options={[
            { value: "date", label: "Date Added" },
            { value: "rating", label: "Rating" },
            { value: "price", label: "Price" },
            { value: "name", label: "Name" },
          ]}
        />

        <Radio.Group
          defaultValue="desc"
          buttonStyle="solid"
          onChange={changeSortOrder}
          style={{ margin: "5px" }}
        >
          <Radio.Button value="desc">
            <VerticalAlignBottomOutlined />
          </Radio.Button>
          <Radio.Button value="asc">
            <VerticalAlignTopOutlined />
          </Radio.Button>
        </Radio.Group>
      </Flex>

      <Select
        allowClear
        style={{ width: "50%" }}
        placeholder="Filter by tags"
        options={FilterTags}
        onChange={filterItems}
      />

      <Flex justify="flex-end">
        <Button
          type="primary"
          icon={<LogoutOutlined />}
          onClick={() => signOut(firebaseAuth)}
          style={{ margin: "5px" }}
        />
      </Flex>
    </Flex>
  );
};

export default ListMenuBar;
