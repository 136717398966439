import {
  CollectionReference,
  DocumentData,
  OrderByDirection,
  collection,
  getDocs,
  orderBy,
  query,
} from "firebase/firestore";
import { FC, createContext, useState } from "react";
import { firestoreDB } from "../../firebaseConfig";
import { WishListProps } from "../../constants/Interfaces";

interface OwnProps {
  children: React.ReactNode;
}

interface WishListContextInterface {
  items: WishListProps[];
  setItems: Function;
  itemsCollectionRef: CollectionReference<DocumentData>;
  sortType: string;
  sortOrder: OrderByDirection;
  setSortType: Function;
  setSortOrder: Function;
  getItems: Function;
}

export const WishListContext = createContext({} as WishListContextInterface);

const WishListProvider: FC<OwnProps> = ({ children }) => {
  const [items, setItems] = useState<any[]>([]);
  const itemsCollectionRef = collection(firestoreDB, 'wishList');
  const [sortType, setSortType] = useState("date");
  const [sortOrder, setSortOrder] = useState<OrderByDirection>("desc");

  //there is an issue I still need to figure out
  //with this change to using a context, it doesn't use the new
  //value that exists in the state, it uses the old one.
  //so while we're setting the state in the menu bar,
  //we still have to pass the new value in as an optional parameter
  const getItems = async (newType?: string, newOrder?: OrderByDirection) => {
    const sortedQuery = query(
      itemsCollectionRef,
      orderBy(newType || sortType, newOrder || sortOrder)
    );
    const data = await getDocs(sortedQuery);

    setItems(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
  };

  return (
    <WishListContext.Provider
      value={{
        items,
        setItems,
        itemsCollectionRef,
        sortType,
        sortOrder,
        setSortType,
        setSortOrder,
        getItems,
      }}
    >
      {children}
    </WishListContext.Provider>
  );
};

export default WishListProvider;
