import { useContext } from 'react';
import { AuthContext } from '../AuthContext';
import AuthPage from '../AuthPage';
import WishListProvider from './WishListContext';
import ListMenuBar from './ListMenuBar';
import WishList from './WishList';
import DocumentTitle from 'react-document-title';

const WishListLanding = () => {
  const { user, loading } = useContext(AuthContext);

  return (
    <div style={{ visibility: loading ? 'hidden' : 'visible' }}>
      <DocumentTitle title="Wish List - Ryan Hunter" />
      {user ? (
        <WishListProvider>
          <ListMenuBar />
          <WishList />
        </WishListProvider>
      ) : (
        <AuthPage />
      )}
    </div>
  );
};

export default WishListLanding;
