import { ArticleContext } from '../../constants/ArticleContext';
import ArticleComposer from './ArticleComposer';
import ProjectComposer from './ProjectComposer';
import { Button, Divider } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { useNavigate, useParams } from 'react-router-dom';
import { useContext } from 'react';
import DocumentTitle from 'react-document-title';
import { AuthContext } from '../AuthContext';
import Error from '../../pages/Error';

const EditorPage = () => {
  const navigate = useNavigate();
  const { editorType, id } = useParams();
  const { articles, projects } = useContext(ArticleContext);
  const { user } = useContext(AuthContext);

  let documentTitle = '';

  const closeAction = () => {
    navigate(-1);
  };

  const getEditor = () => {
    switch (editorType) {
      case 'article':
        documentTitle = id ? 'Edit Article' : 'New Article';
        return (
          <ArticleComposer
            drawerOpen={closeAction}
            article={articles.find(article => article.id === id)}
          />
        );
      case 'project':
        documentTitle = id ? 'Edit Project' : 'New Project';
        return (
          <ProjectComposer
            drawerOpen={closeAction}
            project={projects.find(project => project.id === id)}
          />
        );
    }
  };

  return (
    <div>
      <DocumentTitle title={documentTitle} />
      {user ? (
        <>
          <Button block danger icon={<CloseOutlined />} onClick={closeAction}>
            Cancel
          </Button>
          <Divider />
          {getEditor()}
        </>
      ) : (
        <Error />
      )}
    </div>
  );
};

export default EditorPage;
