import { FC, useContext, useState } from "react";
import { Card, Image, Button, Rate, Typography, Space, Tag, Modal } from "antd";
import { EditOutlined, DeleteOutlined, HeartOutlined } from "@ant-design/icons";
import { openURL } from "../../fragments/Helpers";
import { firestoreDB } from "../../firebaseConfig";
import { doc, deleteDoc } from "firebase/firestore";
import AddWishList from "./AddWishList";
import { WishListProps } from "../../constants/Interfaces";
import { WishListContext } from "./WishListContext";

interface Props {
  item: WishListProps;
}

const WishListItem: FC<Props> = ({ item }) => {
  const [openDrawer, setDrawerOpen] = useState(false);
  const { getItems } = useContext(WishListContext);

  const deleteItem = async (id: string) => {
    const itemDoc = doc(firestoreDB, 'wishList', id);
    await deleteDoc(itemDoc);

    //refresh the list of items
    getItems();
  };

  const closeFunction = () => {
    setDrawerOpen(false);
    getItems();
  };

  return (
    <>
      <Card
        hoverable
        cover={
          <Image
            src={item.image}
            fallback="./images/blank.jpg"
            preview={false}
            onClick={() => openURL(item.link)}
          />
        }
        actions={[
          <Button
            icon={<EditOutlined />}
            onClick={() => setDrawerOpen(true)}
          />,
          <Button
            icon={<DeleteOutlined />}
            onClick={() => deleteItem(item.id)}
          />,
        ]}
      >
        <Card.Meta title={item.name} description={"$" + item.price} />

        <Space direction="vertical">
          <Rate character={<HeartOutlined />} disabled value={item.rating} />
          <div style={{ display: "flex" }}>
            {item.tags?.map((tag) => (
              <Tag key={tag.name} color={tag.color}>
                {tag.name}
              </Tag>
            ))}
          </div>
          <Typography.Text>{item.description}</Typography.Text>
        </Space>
      </Card>

      <Modal
        title="Edit Wish List Item"
        onCancel={() => setDrawerOpen(false)}
        onOk={() => setDrawerOpen(false)}
        open={openDrawer}
        footer={null}
      >
        <AddWishList closeFunction={closeFunction} existingItem={item} />
      </Modal>
    </>
  );
};

export default WishListItem;
